import React, { forwardRef, useImperativeHandle , useRef } from 'react'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, UncontrolledDropdown } from 'reactstrap'
import { BinocularsFill, GripVertical, Valentine, X } from 'react-bootstrap-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import BaseSelect2 from '../dropdowns/BaseSelect2';
import { PdfUploader } from '../widget/PdfUploader';
import { LetterPartTypes } from '../../services/SendMailService';
import DecoratedDateRangePicker from '../widget/DecoratedDateRangePicker';
import OptionYesNo from '../widget/OptionYesNo'
import { dateWithoutTimezone } from '../../services/DateFormatter';

const StatementYesNoOption = ({item, id, text, onChange}) => {
  return (
    <div>
      <OptionYesNo
        onChange={(value => onChange(id, value[id]))}
        id={id}
        text={text}
        initialValue={item.value[id]}
        scale={1.3}
        useFlex={true}
        flexProps={'flex-sm-row flex-md-column flex-xl-row'}
      >
      </OptionYesNo>
    </div>
  )
}

const SortableMailingPart = forwardRef(({ item, onChange, onPreview, onRemove, onIsUploadingChanged, authToken }, ref) => {

  const pdfUploaderRef = useRef(null)

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: item.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  const {role, attributesRest} = {...attributes}
  const handleRemove = async () => {
    console.log('handleRemove', item.id)
    console.log('handleRemove', pdfUploaderRef)

    if ((item.type === LetterPartTypes.Fixed || item.Type === LetterPartTypes.AddressWizard) && pdfUploaderRef && pdfUploaderRef.current) {
      await pdfUploaderRef.current.flush()
      onRemove(item.id)
    }
    else {
      onRemove(item.id)
    }
  }

  const handlePreview = async (previewCount) => {
    console.log('handlePreview', item.id, previewCount)
    onPreview(item.id, previewCount)
  }

  const handleChangeStatement = (property, value) => {
    console.log('handleChangeStatement', property, value)
    onChange(item.id, { value: {
      ...item.value,
      [property]: value
    }})
  }

  const handleChangeStatementOption = (value) => {
    console.log('handleChangeStatementOption', value)
    onChange(item.id, {
      value: {
        ...item.value,
        ['optionValue']: value.value,
        ['optionLabel']: value.label
      },
    })
  }

  const handleChangeStatementDateRange = (property, value) => {
    //console.log('handleChangeStatementDateRange', value[0]) 
    //console.log(dateWithoutTimezone(value[0].startDate));
    //console.log(dateWithoutTimezone(value[0].endDate));

    handleChangeStatement(property, [{
      ...value[0],
      startDate: dateWithoutTimezone(value[0].startDate),
      endDate: dateWithoutTimezone(value[0].endDate)
    }])
  }

  useImperativeHandle(ref, () => {
    return {
      async remove() {
        console.log('remove handle called')

        if ((item.type === LetterPartTypes.Fixed || item.type === LetterPartTypes.AddressWizard) && pdfUploaderRef && pdfUploaderRef.current) {
          await pdfUploaderRef.current.flush()
          return item.id
        }
        else {
          return item.id
        }
      }
    }
  }, [pdfUploaderRef, item])

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributesRest}
      className="sortable-item"
    >
      <div className='' style={{position: 'relative'}}>
        <div className={`si-actions ${item.type} p-1 border rounded`}>
          <div className='d-flex flex-column flex-fill justify-content-between'>
            <div className='si-action'>
              <Button color="light"
                {...listeners}
                innerRef={setActivatorNodeRef}
                className={`si-action sort-action ${isDragging ? 'dragging' : ''} p-1 d-flex flex-items-center`}
                onClick={(e) => e.preventDefault() }
              >
                <GripVertical />
              </Button>
            </div>
            <div className='si-action'>
              {(item.type === LetterPartTypes.Fixed || item.type === LetterPartTypes.AddressWizard) ? (
                <Button
                  color="light"
                  className="preview-action p-1 d-flex"
                  onClick={ () => handlePreview(1) }
                >
                  <BinocularsFill />
                </Button>
              ) : (
                <Button
                  color="light"
                  className="preview-action p-1 d-flex"
                  onClick={ () => handlePreview() }
                >
                  <BinocularsFill />
                </Button>

                // <UncontrolledDropdown>
                //   <DropdownToggle color="light" className="preview-action p-1 d-flex">
                //     <BinocularsFill />
                //   </DropdownToggle>
                //   <DropdownMenu>
                //     <DropdownItem header>Preview How Many?</DropdownItem>
                //     <DropdownItem onClick={ () => handlePreview(1) }>Only 1</DropdownItem>
                //     {/* <DropdownItem onClick={ () => handlePreview(30) }>Up to 30</DropdownItem> */}
                //     <DropdownItem onClick={ () => handlePreview() }>All Selected</DropdownItem>
                //   </DropdownMenu>
                // </UncontrolledDropdown>
              )}
            </div>
            <div className='si-action'>
              {!item.isPermanant && (
                <Button
                  color="light"
                  className="si-action remove-action p-1 d-flex"
                  onClick={handleRemove}
                >
                  <X />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={`si-part ${item.type} ${item.isPermanant ? 'permanant' : ''} p-2 d-block border rounded`}>
          <div className='d-flex flex-fill align-items-center'>
            <div className='d-flex flex-column flex-fill text-start'>
              {item.type === LetterPartTypes.RentManager && (
                <BaseSelect2
                  options={item.options}
                  value={item.value}
                  // customStyles={
                  //   { menu: (provided, state) => {
                  //     return ({
                  //       ...provided,
                  //       zIndex: 20,
                  //       minWidth: '16rem'
                  //     })
                  //   }
                  // }}
                  onChange={(option => onChange(item.id, option))}
                />
              )}
              {item.type === LetterPartTypes.RentManagerCustomForm && (
                <>
                  <BaseSelect2
                    options={item.options}
                    value={item.value.optionValue}
                    onChange={(option => handleChangeStatementOption(option))}
                  />
                  <div className='mb-0 mt-2'>
                    <DecoratedDateRangePicker
                      display='fill'
                      onChange={(selected => handleChangeStatementDateRange('dateSelectionRange', selected))}
                    />
                  </div>
                  <div className='mt-2 ps-3 pe-0 p-1 bg-white border rounded' style={{fontSize: '0.8rem'}}>
                    <StatementYesNoOption
                      item={item}
                      id="showUnpostedPerDayLateFeesToDay"
                      text="Show unposted per day late fees to date"
                      onChange={handleChangeStatement}
                    />
                    <StatementYesNoOption
                      item={item}
                      id="showDailyInterestLoans"
                      text="Show daily interest loans"
                      onChange={handleChangeStatement}
                    />
                    <StatementYesNoOption
                      item={item}
                      id="showMeterReadingsAndUsage"
                      text="Show meter readings and usage"
                      onChange={handleChangeStatement}
                    />
                    <StatementYesNoOption
                      item={item}
                      id="showCalculationDetailsMUPlus"
                      text="Show calculation details (MU-Plus)"
                      onChange={handleChangeStatement}
                    />
                  </div>
                  <div className='form-floating form-floating-textarea mb-0 mt-2'>
                    <TextareaAutosize
                      //type='textarea'
                      maxRows={5}
                      className="form-control"
                      placeholder='Comment'
                      defaultValue={item.value.commentValue}
                      onChange={(e => handleChangeStatement('commentValue', e.target.value))}
                    />
                    <Label className='mb-0'>Comment</Label>
                  </div>
                </>
              )}
              {(item.type === LetterPartTypes.Fixed || item.type === LetterPartTypes.AddressWizard) && (
                <PdfUploader
                  ref={pdfUploaderRef}
                  labelIdle={`Drag & Drop your ${(item.isPermanant ? 'Letters' : 'Static PDF')} or <span class="filepond--label-action">Browse</span>`}
                  labelFileProcessing='Loading Static PDF'
                  labelFileProcessingComplete='Ready'
                  authToken={authToken}
                  //onRemove={onPdfRemove}
                  //onRemoved={onPdfRemoved}
                  onRemoved={(files) => {
                    console.log('onRemoved part static file', files)
                    onChange(item.id, {
                      documentBlobUrl: '', 
                      fixedFiles: files,
                      hasDocument: (files && files.length > 0)
                    })
                  }}
                  onBlobUrlReady={(url, files) => { 
                    console.log('onBlobUrlReady')
                    onChange(item.id, {
                      documentBlobUrl: url, 
                      fixedFiles: files,
                      hasDocument: (files && files.length > 0)
                    })
                  }}
                  //onChange={(files) => dispatch({ type: 'SET', name: 'files', value: { files: files }})}
                  onChange={(files) => {
                    console.log('onChange')
                    // dispatch({ type: 'SET', value: {
                    //   fixedFiles: files
                    // }});
                  }}
                  onIsUploadingChanged={onIsUploadingChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
})

export default SortableMailingPart