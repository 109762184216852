import React, { useReducer, useEffect, useCallback, useRef, forwardRef } from 'react'
import { LetterOptions } from '../../services/SendMailService';
import authService from '../api-authorization/AuthorizeService';

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Col, Row } from 'reactstrap';
import { SortableMailingParts } from './SortableMailingParts';
import { useLetterTemplateService } from '../../services/LetterTemplateService';
import { useCustomFormTemplateService } from '../../services/CustomFormTemplatesService';
import { PdfUploader } from '../widget/PdfUploader';
import { Blank } from '../dropdowns/DefaultSelectOptions';

export const SendMailLetterOptions = forwardRef(({onChange, onPreviewMailingPart, letterTemplateType, customFormTemplateType, customFormTemplate, initialValues, showStatementTemplates = false}, ref) => {
  const _subscription = useRef(null)
  
  // const isLoadingCheck = () => {
  //   var isLoading = filePondRef.current.getFiles().filter(x=>x.status !== 5).length !== 0;
  //   dispatch({ type: 'SET_OPTION', name: 'letterUploading', value: isLoading })
  // }

  const onIsUploadingChanged = useCallback((isUploadingState) => {
    dispatch({ type: 'SET_OPTION', name: 'letterUploading', value: isUploadingState })
  }, [])

  const initialState = initialValues ?? {
    letterOption: initialValues.letterOption ?? LetterOptions.Multi,
    //letterTemplate: initialValues.letterTemplate ?? -1,
    //letterTemplateText: initialValues.letterTemplateText ?? '',
    //letterTemplateLoaded: false,
    isMergeFile: false,
    //fixedFiles: [],
    letterUploading: false,

    letterTemplateOptions: [],
    statementTemplateOptions: [],
    //letterParts: []
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.selected.value,
          [action.name + 'Text']: action.selected.label
        }
      }
      case 'SET_OPTION': {
        return {
          ...state,
          [action.name]: action.value,
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  const letterTemplateService = useLetterTemplateService()
  const customFormTemplateService = useCustomFormTemplateService()

  useEffect(() => {
    _subscription.current = authService.subscribe(() => populateState());
    populateState();

    // Specify how to clean up after this effect:
    return function cleanup() {
        authService.unsubscribe(_subscription.current);
    };
  }, []);

  useEffect(() => {
    let ready = true

    console.log('state changed')

    Object.entries(state).forEach(([key, value]) => {
      if (key.endsWith('Loaded') && value !== true) {
        ready = false
      }
    });

    if (ready) {
      let result = {}
      Object.entries(state).forEach(([key, value]) => {
        if (!key.endsWith('Loaded') /*|| key === 'letterTemplate'*/ || key === 'letterParts') {
          result[key] = value
        }
      })

      //console.log('state result', result)

      onChange(result)
    }
  }, [state])

  const populateState = async () => {
    const [authToken, letterTemplates, statementTemplates] = await Promise.all([
      authService.getAccessToken(),
      state.letterOption === LetterOptions.Multi ? letterTemplateService.GetLetterTemplates(letterTemplateType) : Promise.resolve({}),
      state.letterOption === LetterOptions.Multi && showStatementTemplates ? customFormTemplateService.GetCustomFormTemplates(customFormTemplateType, customFormTemplate) : Promise.resolve({}),
    ])
    
    dispatch({ type: 'SET_OPTION', name: 'authToken', value: authToken })

    if (letterTemplates) {
      dispatch({ type: 'SET_OPTION', name: 'letterTemplateOptions', value: letterTemplates })
    }

    if (statementTemplates) {
      dispatch({ type: 'SET_OPTION', name: 'statementTemplateOptions', value: statementTemplates })
    }
  }

  const mailingPartsChanged = (newItems) => {
    console.log('SET newItems', newItems)
    dispatch({ type: 'SET_OPTION', name: 'letterParts', value: newItems })
  }

  return (
    <React.Fragment>
      {state.letterOption === LetterOptions.Multi ? (
        <>
          <SortableMailingParts
            ref={ref}
            items={state.letterParts}
            letterTemplates={state.letterTemplateOptions}
            statementTemplates={state.statementTemplateOptions}
            showStatementTemplates={showStatementTemplates}
            authToken={state.authToken}
            onChange={mailingPartsChanged}
            onPreviewMailingPart={onPreviewMailingPart}
            onIsUploadingChanged={onIsUploadingChanged}
          />
        </>
      ) : (
        <>
          {/* <div style={{display: (state.letterOption === LetterOptions.Custom ? 'block' : 'none')}}> */}
            <PdfUploader
              labelIdle='Drag & Drop Letter PDF or <span class="filepond--label-action">Browse</span>'
              labelFileProcessing='Loading Letter PDF'
              labelFileProcessingComplete='Letter PDF Loaded'
              authToken={state.authToken}
              //onRemove={onPdfRemove}
              //onRemoved={onPdfRemoved}
              onRemoved={(files) => {
                console.log('onRemoved')
                dispatch({ type: 'SET_OPTION', name: 'fixedFiles', value: files })
              }}
              onBlobUrlReady={(url, files) => { 
                console.log('onBlobUrlReady')
                dispatch({ type: 'SET_OPTION', name: 'fixedFiles', value: files })
                
                //dispatch({ type: 'SET', name: 'documentBlobUrl', value: url}) 
                //dispatch({ type: 'SET', name: 'fixedFiles', value: files})
                //dispatch({ type: 'SET', name: 'hasDocument', value: (files && files.length === 1)})
              }}
              //onChange={(files) => dispatch({ type: 'SET', name: 'files', value: { files: files }})}
              onChange={(files) => {
                console.log('onChange')
                dispatch({ type: 'SET_OPTION', name: 'fixedFiles', value: files })
                // dispatch({ type: 'SET_OPTION', value: {
                //   fixedFiles: files
                // }});
              }}
              onIsUploadingChanged={onIsUploadingChanged}
            />
          {/* </div>

          <div style={{display: (state.letterOption === LetterOptions.Custom ? 'block' : 'none')}}> */}
            <Row>
              <Col sm={8}>Has Designated Letters</Col>
              <Col sm={4} className="d-flex align-items-center">
                <div className="form-check form-switch d-flex justify-content-end ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isMergeFile"
                    name="isMergeFile"
                    onChange={e => dispatch({ type: 'SET_OPTION', name: e.target.name, value: e.target.checked })}
                    checked={state.isMergeFile ? 'checked' : ''}
                    style={{transform: 'scale(1.6)'}}/>
                </div>
              </Col>
            </Row>
          {/* </div> */}
        </>
      )}


    </React.Fragment>
  )
})