import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { Container, Form, FormGroup, Label, Button, Row, Col, Input, InputGroup, InputGroupText, Badge, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ArrowClockwise, BinocularsFill, BoxArrowUpRight, Calculator, Download, EnvelopeExclamation, EnvelopePaperFill, GearWideConnected, InfoCircleFill, InfoLg, Magic, People, PersonLinesFill, Send } from 'react-bootstrap-icons'
import { LetterOptions, LetterPartTypes, useSendMailService } from '../../services/SendMailService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
//import SendMailCustomGrid from './SendMailCustomGrid'
import MailingFields from '../send-mail/MailingFields'
import { CurrencyFormatter } from '../../services/CurrencyFormatter'
import authService from '../api-authorization/AuthorizeService';
import { toast } from 'react-toastify';
import { useDocumentClassService } from '../../services/DocumentClassService'
import SendMailAddressWizardGrid from './SendMailAddressWizardGrid'
import { AddressWizardTemplatesSelect } from './AddressWizardDowns'
import { useSendMailAddressWizardService } from '../../services/SendMailAddressWizardService'
import { BlankGuid } from '../dropdowns/DefaultSelectOptions'
import { 
  ConstantCodeMailFuzeCostCodesEnabled,
  useSettingsService
} from '../../services/SettingsService'
import SendMailValidationResult from '../send-mail-validation/SendMailValidationResult'
import WarningBadAddress from '../send-mail-validation/WarningBadAddress'
import WarningInvalidDocumentDimensions from '../send-mail-validation/WarningInvalidDocumentDimensions'
import WarningLetterNumberPageOfPages from '../send-mail-validation/WarningLetterNumberPageOfPages'
import WarningInvalidClientAddress from '../send-mail-validation/WarningInvalidClientAddress'
import SortablePartsHelp from '../send-mail/SortablePartsHelp'
import { SortableMailingParts } from '../send-mail/SortableMailingParts';

const SendMailAddressWizard = () => {
  let _subscription = null

  const sendMailService = useSendMailService()
  const documentClassService = useDocumentClassService()
  const addressWizardService = useSendMailAddressWizardService()
  const settingService = useSettingsService()

  const downLoadIconRef = useRef(React.createRef());
  const sendMailAddressWizardGridRef = useRef(null)
  const sortableMailingPartsRef = useRef(null)
  
  const initialState = {
    isInitialized: false,
    isLoading: false,
    isValidationErrorsOpen: false,
    templates: [],
    template: BlankGuid.value,
    isFileUploading: false,
    fixedFiles: [],

    listing: [],
    letterOption: LetterOptions.Multi,

    //isHelpOpen: false,
    displayOptions: 'hidden',
    documentClasses: [],
    documentClassOptions: [],
    listingSelection: [],
    mailingOptions: {},
    paymentTypeName: '',
    costCodesEnabled: false,
    recipientsWithoutAddress: [],
    documentDimensionResult: {
      validDocumentDimensions: true,
      targetDocumentDimensions: {
        documentWidth: 8.5,
        documentHeight: 11
      }
    },
    envelopeNumberOfPagesResult: {
      isDuplex: false,
      duplexSupported: false,
      simplexSupported: false,
      maxNumberOfSheets: 0,
      maxNumberOfSheetsDuplex: 0,
    },
    clientAddressValidationResult: {
      isValid: false
    },
    letterUploading: false,
    letterParts: []
    //letterTemplateFields: {
      //letterOption: LetterOptions.Custom,
      //isMergeFile: false,
      //fixedFiles: [],
      //letterUploading: false
    //},
    //recipientSourceFields2: {
      //files: [],
      //fileUploading: false
    //},
  }

  const stateReducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
      // case 'MERGE_LTF': {
      //   return {
      //     ...state,
      //     letterTemplateFields: {
      //       ...state.letterTemplateFields,
      //       ...action.value  
      //     }
      //   }
      // }
      // case 'MERGE_RSF': {
      //   console.log(action.value)
      //   return {
      //     ...state,
      //     recipientSourceFields: {
      //       ...state.recipientSourceFields,
      //       ...action.value  
      //     }
      //   }
      // }
      // case 'MERGE_RSF2': {
      //   console.log(action.value)
      //   return {
      //     ...state,
      //     recipientSourceFields2: {
      //       ...state.recipientSourceFields2,
      //       ...action.value  
      //     }
      //   }
      // }
    }

    return state
  }
  
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const [propertyData, setPropertyData] = useState([])
  //const [loadingPropertyData, setLoadingPropertyData] = useState(false)
  //const [mailingOptions, setMailingOptions] = useState(null)
    //const [gridApi, setGridApi] = useState()
  //const gridRef = useRef();
  //const [gridColumnApi, setGridColumnApi] = useState()
  const MySwal = withReactContent(Swal)
  
  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    _subscription = authService.subscribe(() => populateState());
    populateState();

    // Specify how to clean up after this effect:
    return function cleanup() {
        authService.unsubscribe(_subscription);
    };
  }, []);

  const populateState = async () => {
    const [authToken, templates] = await Promise.all([
      authService.getAccessToken(),
      addressWizardService.GetTemplateItems()
    ])
    
    dispatch({ type: 'SET', name: 'authToken', value: authToken })
    dispatch({ type: 'SET', name: 'templates', value: templates })
    onHideOverlay()
  }


  const load = async () => {
    if (state.documentClassOptions.length == 0 || state.paymentTypeName === '')
    {
      const [defaultPaymentTypeName, documentClasses, documentClassOptions, constantsResults] = await Promise.all([
        sendMailService.GetDetaultPaymentTypeName(),
        documentClassService.GetDocumentClasses(),
        documentClassService.GetDocumentClassOptions(),
        settingService.GetConstants([
          ConstantCodeMailFuzeCostCodesEnabled
        ])
      ])

      const constantCodes = constantsResults.map(constant => constant.value)
      const clientSettings = await settingService.GetClientSettings(constantCodes)

      const mailFuzeCostCodesEnabled = settingService.GetConstantValueFromResults(constantsResults, clientSettings, ConstantCodeMailFuzeCostCodesEnabled, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })

      //copy document classes to blank array
      state.documentClasses.push.apply(state.documentClasses, documentClasses)
      state.documentClassOptions.push.apply(state.documentClassOptions, documentClassOptions)

      dispatch({ type: 'SET', name: 'paymentTypeName', value: defaultPaymentTypeName.value })
      dispatch({ type: 'SET', name: 'displayOptions', value: 'visible' })
      dispatch({ type: 'SET', name: 'costCodesEnabled', value: mailFuzeCostCodesEnabled })
      dispatch({ type: 'SET', name: 'isInitialized', value: true })
    }
  }

  useEffect(() => {
    if (sortableMailingPartsRef && sortableMailingPartsRef.current && state.letterParts.length === 0) {
      sortableMailingPartsRef.current.addStaticPdf(true)
    }
  }, [state.letterParts.length])

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const onShowLoading = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: true })
  }

  const onHideOverlay = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const onMailingFieldsChange = (newMailingOptions) => {
    console.log('Mailing Fields Changed: ', newMailingOptions)
    dispatch({ type: 'SET', name: 'mailingOptions', value: newMailingOptions })
    
    //setMailingOptions(newMailingOptions)
  }

  const onSelectionChanged = (selectedData) => {
    console.log('selectedData',selectedData)
    dispatch({ type: 'SET', name: 'listingSelection', value: selectedData })
  }

  // const onPdfRemove = (files) => {
  //   console.log('onPdfRemove')
  //   //dispatch({ type: 'SET', name: 'documentBlobUrl', value: null })
  // }

  // const onPdfRemoved = (files) => {
  //   console.log('onPdfRemoved')
  //   dispatch({ type: 'SET', name: 'listing', value: [] })
  //   dispatch({ type: 'SET', name: 'fixedFiles', value: [] })
  // }

  const onTemplateChanged = (selected) => {
    if (state.template !== selected.value) {
      dispatch({ type: 'SET', name: 'listing', value: [] })
    }

    dispatch({ type: 'SET', name: 'template', value: selected.value})
  }

  const onLoadAddresses = async () => {

    if (!state.letterParts || state.letterParts.length === 0 || !state.letterParts.filter(x => !x.hasDocument && x.type === LetterPartTypes.AddressWizard)) {
      inputWarning('Missing Letters PDF', 'You must load a PDF containing the letters and addresses.')
      return -1
    }

    if (state.template === BlankGuid.value) {
      inputWarning('No Template Selected', 'You must choose a Template from the list.')
      return -2
    }

    //(state.fixedFiles && state.fixedFiles.length === 0) || state.template === BlankGuid.value}

    onShowLoading()
    const fileId = getFileId()
    const addressResults = await addressWizardService.GetAddressesByTemplate(fileId, state.template)

    const reshapedAddressResults = addressResults.result.map((item) => {
        const { returnAddress, ...reshapeAddress } = item

      if (returnAddress) {
        reshapeAddress.returnName = returnAddress.name
        reshapeAddress.returnStreet1 = returnAddress.street1
        reshapeAddress.returnStreet2 = returnAddress.street2
        reshapeAddress.returnCity = returnAddress.city
        reshapeAddress.returnState = returnAddress.state
        reshapeAddress.returnZip = returnAddress.zip
      }

      return reshapeAddress
    })
    
    dispatch({ type: 'SET', name: 'listing', value: reshapedAddressResults })

    //dispatch({ type: 'MERGE_RSF', name: 'recipients', value: results.result })
    //dispatch({ type: 'SET', name: 'listing', value: results.result })
    onHideOverlay()
    
  }

  const mailingPartsChanged = (newItems) => {
    console.log('SET newItems', newItems)
    
    if (newItems && newItems.length === 0) {
      dispatch({ type: 'SET', name: 'listing', value: [] })
    }

    dispatch({ type: 'SET', name: 'letterParts', value: newItems })
  }

  const handlePreviewMailingPart = async (id, previewCount) => {
    const sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()

    console.log('sanitizedLetterTemplateFields 1', sanitizedLetterTemplateFields)
    console.log('state.letterParts', state.letterParts)

    const index = state.letterParts.findIndex(part => part.id === id)
    const remainder = sanitizedLetterTemplateFields.letterParts.filter((item, i) => i === index)

    sanitizedLetterTemplateFields.letterParts = remainder

    let needsRecipients = true
    let skipSendMailChecks = true
    if (remainder[0].type === LetterPartTypes.Fixed || remainder[0].type === LetterPartTypes.AddressWizard) {
      needsRecipients = false
    }

    return onPreview(null, sanitizedLetterTemplateFields, previewCount, needsRecipients, skipSendMailChecks, true)
  }

  const getFileId = () => {
    const sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()
    const lettersPart = sanitizedLetterTemplateFields.letterParts.find(x => x.isPermanant)
    return lettersPart.fixedFileId
  }

  const getGridRecipients = () => {
    console.log('state.listing', state.listing)
    return state.listing;
  }

  const onIsUploadingChanged = useCallback((isUploadingState) => {
    dispatch({ type: 'SET', name: 'isFileUploading', value: isUploadingState })
  }, [])

  const inputWarning = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'warning',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const showError = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'error',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const validateInputs = (sanitizedLetterTemplateFields = undefined, needsRecipients = true, skipSendMailChecks = false) => {
    const selection = getGridRecipients()
    console.log(selection)

    if (needsRecipients) {
      if (!selection || selection.length === 0) {
        inputWarning('No Recipients Selected', 'You must select at least one mail recipient.')
        return -1
      }
    }

    if (!skipSendMailChecks) {
      // ' All selects must have a valid selection
      if (Object.keys(state.mailingOptions).length === 0) {
        inputWarning(`Invalid Mailing Type`, `You must choose a Mailing Type from the list.`)
        return -2      
      }

      //mailingOptions.envelopeHasOptions = true

      let selectName = null
      let selectLabel = null
      let hasError = false
      Object.entries(state.mailingOptions).forEach(([key, value]) => {
        if (!hasError) {
          selectName = key                                                            .replace('HasOptions', '')
          if (key.endsWith('HasOptions') && value === true && (state.mailingOptions[selectName] === null || state.mailingOptions[selectName] === 0)) {
            selectLabel = state.mailingOptions[selectName + 'Label']
            inputWarning(`Invalid ${selectLabel}`, `You must choose a ${selectLabel} from the list.`)
            hasError = true
          }
        }
      })

      if (hasError)
        return -2

    }

    if (!sanitizedLetterTemplateFields.letterParts || sanitizedLetterTemplateFields.letterParts.length === 0) {
      inputWarning('Invalid Document', 'You must add at least one document using an option from the "Add document" drop down.')
      return -7
    }

    let partError = 0;
    let index = 0;
    do {
      const part = sanitizedLetterTemplateFields.letterParts[index]

      if (part.type === LetterPartTypes.Fixed && !part.hasDocument) {
        inputWarning('Invalid Static PDF', 'You must upload a Static PDF or remove documents without and uploaded PDF.')
        partError = -8
      }

      if (part.type === LetterPartTypes.AddressWizard && !part.hasDocument) {
        inputWarning('Invalid Letters PDF', 'You must upload a Static PDF containing your letters.')
        partError = -9
      }

      index++
      
    } while (index < sanitizedLetterTemplateFields.letterParts.length && partError === 0)

    if (partError !== 0) {
      return partError;
    }


    return 0
  }

  const getSanitizedLetterTemplateFields = () => {

    const stp = sendMailService.GetSanitizedLetterTemplateFields(state)
    const lp = [...stp.letterParts]

    return {
      letterParts: lp,
      ...stp
    }
  }

  const onCostEstimate = async (e) => {
    if (validateInputs(getSanitizedLetterTemplateFields(), true, false) === 0) {
      const selectedData = getGridRecipients()
      console.log(selectedData)
      const selectedRecipientIds = selectedData.map(node => node.pageNum)

      PlainSwal.fire({
        title: 'Calculating Cost Estimate',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          PlainSwal.showLoading()
        },
      })
      
      const body = {
        recipients: selectedRecipientIds,
        ...getSanitizedLetterTemplateFields(),
        ...state.mailingOptions,
        paymentTypeName: state.paymentTypeName
      }

      console.log('body', body)

      sendMailService.GetMailingEstimates(body).then(estimateResult => {
        PlainSwal.close()

        if (!showEstimateError(estimateResult)) {
          const estimate = estimateResult.estimate
          const letterInfo = estimateResult.letterInfo
          const batchInfo = estimateResult.batchInfo
          const sPageSuffix = letterInfo.documentPageCount === 1 ? '' : 's'
          const pageCountWarning = state.letterOption === LetterOptions.Multi ? '' : 'or any indivdiual documents are more than ' + letterInfo.documentPageCount + ' page' + sPageSuffix + '.'
          const splitBatchWarning = batchInfo && !batchInfo.useBatching ? '' : 'Due to the number of recipients and/or the size of your file, the ' + batchInfo.recipientCount + ' mailings will be split into ' + batchInfo.batchCount + ' smaller batches.'

          PlainSwal.fire({
              title: 'Mailing Cost Estimate',
              html: '<div style="text-align: left"><span class="fw-bold">Pages in document: </span>' + letterInfo.documentPageCount + '<br />' +
                  '<span class="fw-bold">Cost for each recipient: </span>' + CurrencyFormatter.format(estimate.perRecipientCost) + '<br />' +
                  '<span class="fw-bold">Number of recipients: </span>' + selectedRecipientIds.length + '<br />' +
                  '<span class="fw-bold">Total cost of mailing: </span>' + CurrencyFormatter.format(estimate.totalCost) + '</div>' +
                  '<div style="color: red; margin-top: 1em">NOTE: This is an ESTIMATE only and cost could be higher if any addresses are non-standard ' +
                  pageCountWarning + '.</span>' +
                  (splitBatchWarning === '' ? '' : '<div style="color: red; margin-top: 1em">NOTE: ' + splitBatchWarning + '</span>'),
              showCloseButton: true,
              buttonsStyling: false,
              customClass: {
                  confirmButton: 'btn btn-primary btn-lg'
              },
          })
        }
      }).catch(error => { PlainSwal.close(); showEstimateError(error); })
    }
  }

  const onPreview = async (e, sanitizedLetterTemplateFields, previewCount = 0, needsRecipients = true, skipSendMailChecks = false, useClassic = true) => {
  //const onPreview = async (e, previewCount = 0) => {

    if (!sanitizedLetterTemplateFields) {
      sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()
    }

    if (validateInputs(sanitizedLetterTemplateFields, needsRecipients, skipSendMailChecks) === 0) {
      const selectedData = getGridRecipients()
      const selectedRecipientIds = selectedData.map(node => node.pageNum)

      let documentClass = -1
      if (state.documentClass !== 0) {
        documentClass = state.documentClass
      }

      //const sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()
      const letterParts = sanitizedLetterTemplateFields.letterParts

      const body = {
        recipients: selectedRecipientIds,
        documentClass,
        previewCount,
        useClassic,
        letterParts: [...letterParts],
        ...sanitizedLetterTemplateFields
      }

      console.log('body', body)

      PlainSwal.fire({
        title: 'Generating Preview',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          PlainSwal.showLoading()
        },
      })

      const result = await sendMailService.GetPreview(body)
        .catch(error => { PlainSwal.close(); showPreviewError(error); })

      if (!showPreviewError(result)) {
        const authToken = await authService.getAccessToken()

        PlainSwal.close()

        console.log(result)

        const dl = PlainSwal.fire({
          title: 'Your Preview is Ready',
          html: downLoadIconRef.current.outerHTML.replace('href="#"', `href="${result.returnDataAsString}" authtoken="${authToken}" onClick="openPdf(event)" id="downloadBtn"`),
          showCloseButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-dark'
          },
          didOpen: function(ele) {
            ele.querySelector('#downloadBtn').addEventListener('click', function(){ dl.close(); }, false);
          }
        })
      }
    }
  }

  const onSend = async (e) => {
    if (validateInputs(getSanitizedLetterTemplateFields(), true, false) === 0) {
      const selectedData = getGridRecipients()
      console.log(selectedData)
      
      const selectedRequestData = selectedData.map(data => { return { 
        ExternalRecipientID: data.pageNum.toString(),
        PageNum: data.pageNum,
        FirstName: data.firstName,
        LastName: data.lastName,
        Street1: data.street1,
        Street2: data.street2,
        City: data.city,
        State: data.state,
        Zip: data.zip,
        CostCode: state.costCodesEnabled ? data.costCode : null,
        ReturnAddress: {
          Name: data.returnName,
          Street1: data.returnStreet1,
          Street2: data.returnStreet2,
          City: data.returnCity,
          State: data.returnState,
          Zip: data.returnZip
        }
      }})

      const selectedRecipientIds = selectedData.map(data => data.pageNum)

      //const estimateResult = await onSendEstimate(selectedRecipientIds)
      const validationResult = await onValidation(selectedRecipientIds, selectedRequestData)
      if (!validationResult.hasEstimateError) {
        if (!showValidationError(validationResult)){
          console.log('Validation completed successufully!', validationResult);
  
          if (validationResult.hasError) {
            console.log('Validation yielded errors!');
  
            console.log('sendMailResults:', validationResult)
            dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: true })
            dispatch({ type: 'SET', name: 'recipientsWithoutAddress', value: validationResult.badAddresses })
            dispatch({ type: 'SET', name: 'documentDimensionResult', value: validationResult.documentDimensionResult })
            dispatch({ type: 'SET', name: 'envelopeNumberOfPagesResult', value: validationResult.envelopeNumberOfPagesResult })
            dispatch({ type: 'SET', name: 'clientAddressValidationResult', value: validationResult.clientAddressValidationResult })
          } else {
            const estimate = validationResult.estimateResult.estimate
            const batchInfo = validationResult.estimateResult.batchInfo
            const splitBatchWarning = batchInfo && !batchInfo.useBatching ? '' : 'Due to the number of recipients and/or the size of your file, the ' + batchInfo.recipientCount + ' mailings will be split into ' + batchInfo.batchCount + ' smaller batches.'
    
            PlainSwal.fire({
              title: 'Confirm Mailing Cost Estimate',
              html:  'The total ESTIMATED cost of this mailing for ' + selectedRecipientIds.length + 
                      ' recipient' + (selectedRecipientIds.length === 1 ? '' : 's') + ' is ' + CurrencyFormatter.format(estimate.totalCost) + '.' +
                      (splitBatchWarning === '' ? '' : '<div style="color: red; margin-bottom: 1em; margin-top: 1em">NOTE: ' + splitBatchWarning + '</div>') +
                      '<p class="fw-bold">Are you sure you want to do this?</p>',
              showCloseButton: true,
              showCancelButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-primary btn-lg me-1',
                cancelButton: 'btn btn-secondary btn-lg ms-1'
              },
              confirmButtonText: 'Confirm',
            }).then(async (result) => {
              if (result.isConfirmed) {
                onSendEstimateConfirmed(selectedRequestData, validationResult.estimateResult)
              }
            })       
          }
        }
      }
    }
  }

  const onValidation = async (selectedRecipientIds, selectedRequestData) => {
    PlainSwal.fire({
      title: 'Validating Submission',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })

    
    const estimateBody = {
      recipients: selectedRecipientIds,
      ...getSanitizedLetterTemplateFields(),
      ...state.mailingOptions,
      paymentTypeName: state.paymentTypeName
    }

    const estimateResult = await sendMailService.GetMailingEstimates(estimateBody).catch(error => { PlainSwal.close(); showEstimateError(error); });
    if (!showEstimateError(estimateResult)) {

      const sendMailBody = {
        recipients: selectedRequestData,
        ...getSanitizedLetterTemplateFields(),
        ...state.mailingOptions,
        estimate: estimateResult,
        paymentTypeName: state.paymentTypeName
      }

      const validationResult = await sendMailService.ValidateSendMailToAddressWizardRecipients(sendMailBody).catch(error => { PlainSwal.close(); showValidationError(error); });

      PlainSwal.close()

      return {
        ...validationResult,
        estimateResult
      }
    }

    return {
      hasEstimateError: true
    }
  }


  // const onSendEstimate = async (selectedRecipientIds) => {
  //   PlainSwal.fire({
  //     title: 'Calculating Cost Estimate',
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timerProgressBar: true,
  //     didOpen: () => {
  //       PlainSwal.showLoading()
  //     },
  //   })

  //   const estimateBody = {
  //     recipients: selectedRecipientIds,
  //     ...getSanitizedLetterTemplateFields(),
  //     ...state.mailingOptions,
  //     paymentTypeName: state.paymentTypeName
  //   }

  //   console.log('estimateBody', estimateBody)

  //   const result = await sendMailService.GetMailingEstimates(estimateBody).catch(error => { PlainSwal.close(); showEstimateError(error); });

  //   PlainSwal.close()

  //   return result;
  // }

  const showEstimateError = (estimateResult) => {
    if (!estimateResult || estimateResult.returnCode !== 0) {
      showError("Error Getting Cost Estimate", estimateResult && estimateResult.errorMessage)
      console.log(estimateResult)
      return true
    }
    
    return false
  }

  const showValidationError = (validationResult) => {
    if (!validationResult || !validationResult.isSuccess) {
      if (validationResult && validationResult.hasEstimateError) {
        return false
      }

      showError("Error Occurred During Validation", validationResult && validationResult.errorMessage)
      console.log(validationResult)
      return true
    }
    
    return false
  }

  const showPreviewError = (previewResult) => {
    if (!previewResult || previewResult.returnCode !== 0) {
      showError("Error Generating Preview", previewResult && previewResult.errorMessage)
      console.log(previewResult)
      return true
    }
    
    return false
  }

  const onSendEstimateConfirmed = async (selectedRequestData, estimateResults) => {
    console.log('Confirmed')

    const sendMailBody = {
      recipients: selectedRequestData,
      ...getSanitizedLetterTemplateFields(),
      ...state.mailingOptions,
      estimate: estimateResults,
      paymentTypeName: state.paymentTypeName
    }

    console.log('sendMailBody', sendMailBody)

    PlainSwal.fire({
      title: 'Processing',
      html: '<div id="processingText">Submitting</div>',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })

    sendMailService.CallSendMailToAddressWizardRecipient(sendMailBody).then(sendMailResults => {

      PlainSwal.close()
      if (sendMailResults.isSuccess) {
        toast.success('Send Mail Submitted', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      }
      else {
        console.log('sendMailResults:', sendMailResults)
        dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: true })
        dispatch({ type: 'SET', name: 'recipientsWithoutAddress', value: sendMailResults.badAddresses })
        dispatch({ type: 'SET', name: 'documentDimensionResult', value: sendMailResults.documentDimensionResult })
        dispatch({ type: 'SET', name: 'envelopeNumberOfPagesResult', value: sendMailResults.envelopeNumberOfPagesResult })
        dispatch({ type: 'SET', name: 'clientAddressValidationResult', value: sendMailResults.clientAddressValidationResult })
        
        // toast.error('Send Mail Failed', {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        // })
      }
      
    }).catch(error => { 
      console.log(error);
      PlainSwal.close();
      toast.error('Send Mail Failed', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    });
  }

  return (
    <>
      <div className="d-none">
        <div ref={downLoadIconRef}>
          <Button color="primary" className="btn-lg" href="#"><Download /> Download Preview</Button>
        </div>
      </div>
      <Container>
        <Row style={{visibility: state.displayOptions}}>
          <Col md={12} lg={9}>
            <Form>
              <Row>
                <div className="border p-3" style={{flex: '0 0 auto', maxWidth: '100%' }}>
                  <Col>
                    <fieldset className='pb-0 mb-0'>
                      <legend>
                        <div className='d-flex flex-row justify-content-between mb-1'>
                          {/* remove mb-1 when putting help back in. */}
                          <span><Badge><EnvelopePaperFill /></Badge> Letters</span>
                          {/* <Button
                            //onClick={onOpenHelp}
                            color='primary'
                            style={{ transform: 'scale(0.9)' }}
                            className='btn-circle d-flex flex-column justify-content-center align-items-center'
                          >
                              <InfoLg size={28} style={{width: '28px', height: '28px' }} />
                          </Button> */}
                        </div>
                      </legend>
                      <hr />
                      <SortableMailingParts
                        ref={sortableMailingPartsRef}
                        showRentManagerTemplates={false}
                        items={state.letterParts}
                        authToken={state.authToken}
                        onChange={mailingPartsChanged}
                        onPreviewMailingPart={handlePreviewMailingPart}
                        onIsUploadingChanged={onIsUploadingChanged}
                      />
                    </fieldset>

                    <hr />

                    <fieldset>
                      <legend>
                        <div className='d-flex flex-row justify-content-between'>
                          <span><Badge><Magic /></Badge> Address Wizard Template</span>
                        </div>
                      </legend>

                      <div className='d-flex flex-row w-100'>
                        {/* <div className='d-flex flex-grow-0 align-items-center pe-3'>
                          Template:
                        </div> */}
                        <div className='d-flex flex-grow-1'>
                          <Col>
                            <AddressWizardTemplatesSelect
                              className="w-100 border text-danger"
                              options={state.templates}
                              value={state.template}
                              onChange={onTemplateChanged}
                            />
                          </Col>
                        </div>
                        <div className='d-flex flex-grow-0 align-items-center ps-3'>
                          <Button
                            color='dark'
                            onClick={onLoadAddresses}
                            disabled={state.isFileUploading}
                          >Load Addresses</Button>
                        </div>
                      </div>
                    </fieldset>
                  </Col>
                </div>
              </Row>
              <Row>
                <div className="m-0 mt-4 p-0" style={{flex: '0 0 auto', maxWidth: '100%' }}>
                  {state.isInitialized && (
                    <SendMailAddressWizardGrid
                      ref={sendMailAddressWizardGridRef}
                        isLoading={state.isLoading}
                        listingData={state.listing}
                        onSelectionChanged={onSelectionChanged}
                        costCodesEnabled={state.costCodesEnabled}
                    />
                  )}
                </div>
              </Row>
            </Form>
          </Col>
          <Col md={12} lg={3}>
            <div className="border ml-3 p-3" style={{flex: '0 0 auto', maxWidth: '100%', height: '100%'}}>
              <Row>
                <Col>
                  <fieldset className="mt-3">
                    <legend><Badge><GearWideConnected /></Badge> Mail Options</legend>
                    <hr />
                    <MailingFields
                      onChange={onMailingFieldsChange}
                      documentClasses={state.documentClasses}
                      documentClassOptions={state.documentClassOptions}
                    />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col className="w-100 text-center">
                  <Button color="dark" onClick={onCostEstimate} disabled={state.isFileUploading}><Calculator /> Cost Estimate</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <React.Fragment>
                  <Col sm={6} md={12} xxl={6}>
                    <Button color="dark" className="w-100" onClick={ onPreview } disabled={state.isFileUploading}><BinocularsFill /> Preview</Button>
                  </Col>
                  <Col sm={6} md={12} xxl={6} className="mt-3 mt-sm-0 mt-md-3 mt-xxl-0">
                    <Button color="dark" className="w-100" onClick={ onSend } disabled={state.isFileUploading}><Send /> Send</Button>
                  </Col>
                </React.Fragment>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <SendMailValidationResult
        showCanvas={state.isValidationErrorsOpen}
        onClose={() => dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: false })}
      >
        {state.envelopeNumberOfPagesResult && !state.envelopeNumberOfPagesResult.isValid && (
          <WarningLetterNumberPageOfPages
            result={state.envelopeNumberOfPagesResult}
          />
        )}

        {state.documentDimensionResult && !state.documentDimensionResult.validDocumentDimensions && (
          <WarningInvalidDocumentDimensions
            validDocumentDimensions={state.documentDimensionResult.validDocumentDimensions}
            targetDocumentWidth={state.documentDimensionResult.targetDocumentDimensions.documentWidth}
            targetDocumentHeight={state.documentDimensionResult.targetDocumentDimensions.documentHeight}
          />
        )}

        {state.clientAddressValidationResult && !state.clientAddressValidationResult.isValid && (
          <WarningInvalidClientAddress
            result={state.clientAddressValidationResult}
          />
        )}

        {state.recipientsWithoutAddress && state.recipientsWithoutAddress.length !== 0 && (
          <WarningBadAddress
            hasUnselect={false}
            recipientsWithoutAddress={state.recipientsWithoutAddress}
          />
        )}
      </SendMailValidationResult>
    </>
  )
}

export default SendMailAddressWizard